export const VOICES = [
  {
    Gender: "Female",
    Id: "Lotte",
    LanguageCode: "nl-NL",
    LanguageName: "Dutch",
    Name: "Lotte",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Maxim",
    LanguageCode: "ru-RU",
    LanguageName: "Russian",
    Name: "Maxim",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Salli",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Salli",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Male",
    Id: "Geraint",
    LanguageCode: "en-GB-WLS",
    LanguageName: "Welsh English",
    Name: "Geraint",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Miguel",
    LanguageCode: "es-US",
    LanguageName: "US Spanish",
    Name: "Miguel",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Marlene",
    LanguageCode: "de-DE",
    LanguageName: "German",
    Name: "Marlene",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Giorgio",
    LanguageCode: "it-IT",
    LanguageName: "Italian",
    Name: "Giorgio",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Ines",
    LanguageCode: "pt-PT",
    LanguageName: "Portuguese",
    Name: "Inês",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Zeina",
    LanguageCode: "arb",
    LanguageName: "Arabic",
    Name: "Zeina",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Zhiyu",
    LanguageCode: "cmn-CN",
    LanguageName: "Chinese Mandarin",
    Name: "Zhiyu",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Gwyneth",
    LanguageCode: "cy-GB",
    LanguageName: "Welsh",
    Name: "Gwyneth",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Karl",
    LanguageCode: "is-IS",
    LanguageName: "Icelandic",
    Name: "Karl",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Joanna",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Joanna",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Female",
    Id: "Lucia",
    LanguageCode: "es-ES",
    LanguageName: "Castilian Spanish",
    Name: "Lucia",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Cristiano",
    LanguageCode: "pt-PT",
    LanguageName: "Portuguese",
    Name: "Cristiano",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Astrid",
    LanguageCode: "sv-SE",
    LanguageName: "Swedish",
    Name: "Astrid",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Vicki",
    LanguageCode: "de-DE",
    LanguageName: "German",
    Name: "Vicki",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Mia",
    LanguageCode: "es-MX",
    LanguageName: "Mexican Spanish",
    Name: "Mia",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Bianca",
    LanguageCode: "it-IT",
    LanguageName: "Italian",
    Name: "Bianca",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Vitoria",
    LanguageCode: "pt-BR",
    LanguageName: "Brazilian Portuguese",
    Name: "Vitória",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Raveena",
    LanguageCode: "en-IN",
    LanguageName: "Indian English",
    Name: "Raveena",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Chantal",
    LanguageCode: "fr-CA",
    LanguageName: "Canadian French",
    Name: "Chantal",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Amy",
    LanguageCode: "en-GB",
    LanguageName: "British English",
    Name: "Amy",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Male",
    Id: "Brian",
    LanguageCode: "en-GB",
    LanguageName: "British English",
    Name: "Brian",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Male",
    Id: "Russell",
    LanguageCode: "en-AU",
    LanguageName: "Australian English",
    Name: "Russell",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Aditi",
    LanguageCode: "en-IN",
    LanguageName: "Indian English",
    Name: "Aditi",
    AdditionalLanguageCodes: ["hi-IN"],
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Matthew",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Matthew",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Female",
    Id: "Dora",
    LanguageCode: "is-IS",
    LanguageName: "Icelandic",
    Name: "Dóra",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Enrique",
    LanguageCode: "es-ES",
    LanguageName: "Castilian Spanish",
    Name: "Enrique",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Hans",
    LanguageCode: "de-DE",
    LanguageName: "German",
    Name: "Hans",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Carmen",
    LanguageCode: "ro-RO",
    LanguageName: "Romanian",
    Name: "Carmen",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Ivy",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Ivy",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Female",
    Id: "Ewa",
    LanguageCode: "pl-PL",
    LanguageName: "Polish",
    Name: "Ewa",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Maja",
    LanguageCode: "pl-PL",
    LanguageName: "Polish",
    Name: "Maja",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Nicole",
    LanguageCode: "en-AU",
    LanguageName: "Australian English",
    Name: "Nicole",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Filiz",
    LanguageCode: "tr-TR",
    LanguageName: "Turkish",
    Name: "Filiz",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Jacek",
    LanguageCode: "pl-PL",
    LanguageName: "Polish",
    Name: "Jacek",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Justin",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Justin",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Female",
    Id: "Celine",
    LanguageCode: "fr-FR",
    LanguageName: "French",
    Name: "Céline",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Kendra",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Kendra",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Male",
    Id: "Ricardo",
    LanguageCode: "pt-BR",
    LanguageName: "Brazilian Portuguese",
    Name: "Ricardo",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Mads",
    LanguageCode: "da-DK",
    LanguageName: "Danish",
    Name: "Mads",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Mathieu",
    LanguageCode: "fr-FR",
    LanguageName: "French",
    Name: "Mathieu",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Lea",
    LanguageCode: "fr-FR",
    LanguageName: "French",
    Name: "Léa",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Naja",
    LanguageCode: "da-DK",
    LanguageName: "Danish",
    Name: "Naja",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Penelope",
    LanguageCode: "es-US",
    LanguageName: "US Spanish",
    Name: "Penélope",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Tatyana",
    LanguageCode: "ru-RU",
    LanguageName: "Russian",
    Name: "Tatyana",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Ruben",
    LanguageCode: "nl-NL",
    LanguageName: "Dutch",
    Name: "Ruben",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Mizuki",
    LanguageCode: "ja-JP",
    LanguageName: "Japanese",
    Name: "Mizuki",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Takumi",
    LanguageCode: "ja-JP",
    LanguageName: "Japanese",
    Name: "Takumi",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Conchita",
    LanguageCode: "es-ES",
    LanguageName: "Castilian Spanish",
    Name: "Conchita",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Carla",
    LanguageCode: "it-IT",
    LanguageName: "Italian",
    Name: "Carla",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Kimberly",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Kimberly",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Male",
    Id: "Jan",
    LanguageCode: "pl-PL",
    LanguageName: "Polish",
    Name: "Jan",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Liv",
    LanguageCode: "nb-NO",
    LanguageName: "Norwegian",
    Name: "Liv",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Male",
    Id: "Joey",
    LanguageCode: "en-US",
    LanguageName: "US English",
    Name: "Joey",
    SupportedEngines: ["neural", "standard"]
  },
  {
    Gender: "Female",
    Id: "Seoyeon",
    LanguageCode: "ko-KR",
    LanguageName: "Korean",
    Name: "Seoyeon",
    SupportedEngines: ["standard"]
  },
  {
    Gender: "Female",
    Id: "Emma",
    LanguageCode: "en-GB",
    LanguageName: "British English",
    Name: "Emma",
    SupportedEngines: ["neural", "standard"]
  }
];
